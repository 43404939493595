import './App.css';

function App() {
  return (
    <div className="App">
      <link href="https://fonts.googleapis.com/css?family=Fredoka+One" rel="stylesheet"></link>
      <header className="App-header">
        <b>test002</b>
        <b>{process.env.REACT_APP_TEST_VAL1}</b>
        <b class="ff_fredoka">{process.env.REACT_APP_TEST_VAL2}</b>
      </header>
    </div>
  );
}

export default App;
